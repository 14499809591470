import { OrganizationalContainer, OrganizationalContainerSource } from '@trustedshops/tswp-core-masterdata';
import { Injectable } from '@angular/core';

@Injectable()
export class DefaultOrganizationalContainerSource implements OrganizationalContainerSource<any> {
  public get type(): string {
    return 'null';
  }

  public async getContainers(): Promise<OrganizationalContainer<any>[]> {
    return null;
  }
}
