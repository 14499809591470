
<div class="sidebar">
  <ng-container *ngIf="activeItems && (activeItems | async) as activeItems$">
    <div class="sidebar-container">
      <ng-container *ngIf="(sidebarPrimaryItems | async) as sidebarPrimaryItems$">
        <nav class="sidebar-container-primary_menu"
              [ngClass]="(isNavigationCollapsed | async)
                ? 'sidebar-container-primary_menu--collapsed'
                : 'sidebar-container-primary_menu--expanded'">
          <ul>
            <ng-container *ngFor="let navigationItem of sidebarPrimaryItems$ | sort:'order'">
              <li class="sidebar-container-primary_menu-item"
                  *ngIf="navigationItem.isVisible.value">
                <user-interaction [interaction]="navigationItem.userInteraction"
                                  [renderingOptions]="{
                                    linkActiveOptions: {exact: false},
                                    forcedActive: activeItems$.primary.includes(navigationItem)
                                  }"
                                  class="sidebar-container-primary_menu-item-user_interaction">

                  <div
                    class="sidebar-container-primary_menu-item-content"
                    [matTooltip]="navigationItem.label"
                    matTooltipPosition="right">
                    <span class="sidebar-container-primary_menu-item-icon">
                      <span [ngClass]="navigationItem.icon"></span>
                    </span>
                    <span class="sidebar-container-primary_menu-item-label"
                          [innerText]="navigationItem.label"></span>
                  </div>
                </user-interaction>
              </li>
            </ng-container>
            <li class="sidebar-container-primary_menu-item sidebar-container-primary_menu-item--align-bottom">
              <a (click)="togglePrimaryMenu()" (keydown)="togglePrimaryMenu()" tabindex="0">
                <span class="sidebar-container-primary_menu-item-icon">
                  <span class="helios-icon"
                        [ngClass]="(isNavigationCollapsed | async)
                          ? 'helios-icon-arrow-chevron-right'
                          : 'helios-icon-arrow-chevron-left'"></span>
                </span>
              </a>
            </li>
          </ul>
        </nav>
      </ng-container>
      <ng-container *ngIf="(sidebarSecondaryItems | async) as sidebarSecondaryItems$">
        <div  class="sidebar-container-secondary_menu"
              *ngIf="sidebarSecondaryItems$ && sidebarSecondaryItems$.length">
          <nav>
            <ul>
              <ng-container
                *ngFor="let navigationItem of sidebarSecondaryItems$ | sort:'order'">
                <li
                  class="sidebar-container-secondary_menu-item"
                  *ngIf="navigationItem.isVisible.value">
                  <user-interaction [interaction]="navigationItem.userInteraction"
                                    [renderingOptions]="{
                                      linkActiveOptions: { exact: true },
                                      forcedActive: activeItems$.secondary.includes(navigationItem)
                                    }"
                                    class="sidebar-container-secondary_menu-item-user_interaction">
                    <span [innerText]="navigationItem.label"></span>
                  </user-interaction>
                </li>
              </ng-container>
            </ul>
          </nav>
          <nav class="sidebar-container-legal_menu">
            <ul>
              <li *ngFor="let navigationItem of sidebarLegalItems | sort:'order'">
                <user-interaction [interaction]="navigationItem.userInteraction">
                  <span [innerText]="navigationItem.label"></span>
                </user-interaction>
              </li>
            </ul>
          </nav>
        </div>
      </ng-container>

    </div>
  </ng-container>
  <div class="sidebar-content">
    <ng-content></ng-content>
  </div>
</div>
