import { Component, OnDestroy } from '@angular/core';
import { SubscriberComponent } from '@trustedshops/tswp-core-ui-implementation';
import { Subscription } from 'rxjs';
@Component({
  template: '',
})
export class RxJsSubscriberComponent extends SubscriberComponent implements OnDestroy {
   //#region Private Fields
   private readonly _subscriptions: Subscription[] = [];
   //#endregion

   /**
    * Removes all remembered subscriptions
    */
   public ngOnDestroy(): void {
     this._subscriptions.forEach(subscription =>
       subscription?.unsubscribe());
   }

   /**
    * Utility method to register a created subscription.
    * When the child class does not override/extend the `ngOnDestroy` method,
    * the registered subscriptions are going to be unsubscribed.
    *
    * @param subscription The subscription to unregister on component destruction
    */
   protected rememberSubscription(...subscriptions: Subscription[]): SubscriberComponent {
     this._subscriptions.push(...subscriptions);
     return this;
   }
}
