import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TOKENS, NavigationService, NavigationItem, BreadcrumbsCompositionService, UserInteractionHandler } from '@trustedshops/tswp-core-ui';
import { Observable } from 'rxjs';
import { NavigationRegions } from '@trustedshops/tswp-carrier-employees-contracts';
import { RxJsSubjectBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';

@Component({
  selector: 'header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class HeaderBarComponent implements OnInit {
    //#region Private Fields
    private readonly _navigationService: NavigationService;
    private readonly _breadcrumbsCompositionService: BreadcrumbsCompositionService;
    //#endregion

    //#region Properties
    private _breadcrumbs: Observable<NavigationItem[]>;
    public get breadcrumbs(): Observable<NavigationItem[]> {
      return this._breadcrumbs;
    }

    private _metaMenuItems: NavigationItem[];
    public get metaMenuItems(): NavigationItem[] {
      return this._metaMenuItems;
    }
    public set metaMenuItems(v: NavigationItem[]) {
      this._metaMenuItems = v;
    }
    //#endregion

    //#region Ctor
    public constructor(
      @Inject(TOKENS.NavigationService) navigationService: NavigationService,
      @Inject(TOKENS.BreadcrumbsCompositionService) breadcrumbsCompositionService: BreadcrumbsCompositionService) {

      this._navigationService = navigationService;
      this._breadcrumbsCompositionService = breadcrumbsCompositionService;
    }
    //#endregion

    //#region Public Methods
    public async ngOnInit(): Promise<void> {
      this._metaMenuItems = this._navigationService.getItemsForRegion(NavigationRegions.MetaMenu);
      this._breadcrumbs = this._breadcrumbsCompositionService.currentBreadcrumbTrail.convertWith(RxJsSubjectBridge<NavigationItem<any, UserInteractionHandler>[]>);
    }
    //#endregion
}
