import { Component, Inject, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import {
  TOKENS,
  NavigationService,
  NavigationItem,
  NavigateUserInteractionHandler,
  NavigationRegionService,
} from '@trustedshops/tswp-core-ui';
import { of, BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NavigationRegions } from '@trustedshops/tswp-carrier-employees-contracts';
import { DOCUMENT } from '@angular/common';
import { RxJsSubscriberComponent } from '../../core/rxjs-subscriber.component';
import { RxJsBridge, RxJsSubjectBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { AbstractPersistentEvent, LocalStorageService, TOKENS as TOKENS_COMMON, Event } from '@trustedshops/tswp-core-common';
import { primaryNavigationConfiguration } from './primary-menu.navigation-region';
import { secondaryNavigationRegion } from './secondary-menu.navigation-region';
import { tertiaryNavigationRegion } from '../tertiary-menu-bar/tertiary-menu.navigation-region';

const LOCAL_STORAGE_COLLAPSED_KEY = 'application.navigation.isCollapsed';

type ActiveNavigationItemsStream = Observable<{
  primary: NavigationItem[];
  secondary: NavigationItem[];
  tertiary: NavigationItem[];
}>;

@Component({
  selector: 'sidebar',
  styleUrls: ['./sidebar.component.scss'],
  templateUrl: './sidebar.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SidebarComponent extends RxJsSubscriberComponent implements OnInit {
  //#region Properties
  public homeNavigationItem: NavigationItem = {
    label: of('Home'),
    icon: of('helios-icon helios-icon-feature-navigation-home'),
    userInteraction: of(new NavigateUserInteractionHandler('/')),
    children: [],
    order: 1000,
    $id: 'home'
  } as any;

  private _activeItems: ActiveNavigationItemsStream = null;
  public get activeItems(): ActiveNavigationItemsStream {
    return this._activeItems;
  }
  public set activeItems(v: ActiveNavigationItemsStream) {
    this._activeItems = v;
  }

  private _current$isNavigationCollapsed: boolean;
  private _isNavigationCollapsed: Observable<boolean>;
  public get isNavigationCollapsed(): Observable<boolean> {
    if (!this._isNavigationCollapsed) {
      this._isNavigationCollapsed = this._navigationRegionService
        .isRegionCollapsed(NavigationRegions.PrimaryMenu)
        .convertWith(RxJsSubjectBridge<boolean>);
    }
    return this._isNavigationCollapsed;
  }

  private _sidebarPrimaryItems: BehaviorSubject<NavigationItem[]> = new BehaviorSubject([]);
  public get sidebarPrimaryItems(): Observable<NavigationItem[]> {
    return this._sidebarPrimaryItems;
  }

  private _sidebarSecondaryItems: BehaviorSubject<NavigationItem[]> = new BehaviorSubject([]);
  public get sidebarSecondaryItems(): Observable<NavigationItem[]> {
    return this._sidebarSecondaryItems;
  }

  private _sidebarLegalItems: NavigationItem[] = [];
  public get sidebarLegalItems(): NavigationItem[] {
    return this._sidebarLegalItems;
  }
  public set sidebarLegalItems(v: NavigationItem[]) {
    this._sidebarLegalItems = v;
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(DOCUMENT)
    private readonly _document: Document,

    @Inject(TOKENS.NavigationService)
    private readonly _navigationService: NavigationService,

    @Inject(TOKENS.NavigationRegionService)
    private readonly _navigationRegionService: NavigationRegionService,

    @Inject(TOKENS_COMMON.LocalStorageService)
    private readonly _localStorageService: LocalStorageService,

    private readonly _elementRef: ElementRef) {
    super();
  }
  //#endregion

  //#region Public Methods
  public getTooltip(label: string): string {
    return this._current$isNavigationCollapsed || this.isOverflowing(label)
        ? label
        : null;
  }

  public isOverflowing(label: string): boolean {
    const el = this._document.createElement('span');
    el.innerText = label;
    el.className = 'sidebar-container-primary_menu-item__content__label--width-test';
    this._elementRef.nativeElement.appendChild(el);
    const isOverflowing = el.offsetWidth < el.scrollWidth;
    this._elementRef.nativeElement.removeChild(el);
    return isOverflowing;
  }

  public async ngOnInit(): Promise<void> {
    this._navigationService.registerNavigationItems([], NavigationRegions.PrimaryMenu);
    this._sidebarPrimaryItems.next(this._navigationService.getItemsForRegion(NavigationRegions.PrimaryMenu));
    this._sidebarLegalItems = this._navigationService.getItemsForRegion(NavigationRegions.LegalMenu);

    this._navigationRegionService.registerRegionConfig(secondaryNavigationRegion);
    const initiallyCollapsed = this.getInitialFoldingStatus();
    this._navigationRegionService.registerRegionConfig(primaryNavigationConfiguration(initiallyCollapsed));
    this._navigationRegionService.registerRegionConfig(tertiaryNavigationRegion);


    const selectorRelevantNavigationRegions = [
      NavigationRegions.PrimaryMenu,
      NavigationRegions.SecondaryMenu,
      NavigationRegions.TertiaryMenu,
    ];

    const activeItemStreams = selectorRelevantNavigationRegions
      .map(region => this._navigationRegionService.getActiveItems(region))
      .map(regionItems => regionItems.convertWith(RxJsBridge(BehaviorSubject)));

    this.activeItems = combineLatest(activeItemStreams)
      .pipe(map(([primary, secondary, tertiary]) => [primary || [], secondary || [], tertiary || [] ]))
      .pipe(map(([primary, secondary, tertiary]) => ({ primary, secondary, tertiary})));

    this.rememberSubscription(
      this.isNavigationCollapsed
        .subscribe(isCollapsed => {
          this._localStorageService.insertOrUpdate(LOCAL_STORAGE_COLLAPSED_KEY, isCollapsed);
          this._current$isNavigationCollapsed = isCollapsed;
        }),

      this._activeItems
        .pipe(filter(items => !!items))
        .pipe(map(x => x.primary))
        .pipe(distinctUntilChanged())
        .subscribe(items => this.loadChildren(items)));
  }

  public togglePrimaryMenu(): void {
    this._navigationRegionService.toggleRegion(NavigationRegions.PrimaryMenu);
  }
  //#endregion

  //#region Private Methods
  private getInitialFoldingStatus(): Event<boolean> {
    let wasCollapsed = this._localStorageService.select<boolean | null>(LOCAL_STORAGE_COLLAPSED_KEY);
    if (wasCollapsed === null) {
      wasCollapsed = true;
    }
    return new AbstractPersistentEvent(wasCollapsed);
  }

  private async loadChildren(currentPrimaryNavigationItems: NavigationItem[]): Promise<void> {
    if (!currentPrimaryNavigationItems) {
      return;
    }

    const children = currentPrimaryNavigationItems
      .map(x => x?.children || [])
      .reduce((prev, cur) => prev.concat(cur), []);

    this._sidebarSecondaryItems.next(children || []);

    if (Array.isArray(children) && children.length > 0) {
      this._navigationRegionService.collapseRegion(NavigationRegions.PrimaryMenu);
    }
  }
  //#endregion
}
